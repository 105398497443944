import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { NewMarketCommunityHistory } from 'components/NewMarketCommunityHistory'
import { NewMarketCommunityGeo } from 'components/NewMarketCommunityGeo'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { NewMarketCommunityHero } from 'components/NewMarketCommunityHero'
import { NewMarketCommunityInformation } from 'components/NewMarketCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchNewMarket'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'
import PropertiesComponent from 'components/PropertiesComponentBrampton'
import { FlexDiv } from 'design-system/FlexDiv'
import { Contact } from 'components/Contact'

const BramptonPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <FlexDiv className="cityPage">
        <PropertiesComponent defaultCity="Brampton" />
        <Contact />
        </FlexDiv>
    </EllicotLayout>
  )
}

export default BramptonPage
